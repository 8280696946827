import { routePaths } from "suited/constants/routePaths";

export const assessmentItemTypes = {
  MATRIXTABLE: "MATRIXTABLE",
  MULTISELECT: "MULTISELECT",
  TEXTINPUT: "TEXTINPUT",
  MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
  POINTALLOCATION: "POINTALLOCATION"
} as const;

export const assessmentStatusTypes = {
  READY: "READY",
  COMPLETE: "COMPLETE"
} as const;

export const sectionStatusTypes = {
  READY: "READY",
  COMPLETE: "COMPLETE",
  INCOMPLETE: "INCOMPLETE"
} as const;

export const assessmentViews = {
  SECTION_INFO: "SECTION_INFO",
  SECTION_QUESTIONS: "SECTION_QUESTIONS",
  END_OF_ASSESSMENT: "END_OF_ASSESSMENT"
};

export const ECA_INSTRUCTIONS = [
  {
    headline: "DON’T GUESS",
    copy: "Wrong answers will negatively impact your score, so only answer if you’re confident. Aim for both speed and accuracy. ",
    icon: ["fal", "circle-question"]
  },
  {
    headline: "COMPUTER REQUIRED",
    copy: "You will not be able to see all items on a phone screen, so please use a laptop as mobile devices are not supported.",
    icon: ["fal", "mobile"]
  },
  {
    headline: "TIME LIMIT",
    copy: "You have %TIME_MINUTES% minutes to complete the assessment. Ensure a distraction-free environment, and a good internet connection.",
    icon: ["fal", "clock"]
  },
  {
    headline: "SINGLE ATTEMPT",
    copy: "Once a section is completed, you cannot revisit it. Once you submit your answers, you cannot retake the assessment.",
    icon: ["fal", "redo"]
  }
];

export const mockSectionIDs = {
  MATRIX_TABLE: "7340660f-50e1-4e74-accb-505ac10cff09",
  MULTI_SELECT: "9b87ea09-56f5-4d6f-8a30-0894b95fe2f1",
  TEXT_INPUT: "f81d52db-229a-4414-bdee-75b9092d12df",
  SAME_DIFFERENT_MATRIX_TABLE: "781919bc-f176-4017-b939-ed66510c6018",
  MULTIPLE_CHOICE: "9716d066-df58-4a07-990c-87101c8cd7f1"
};

export const mockItemGroupIDs = {
  MATRIX_TABLE: "d6290845-73eb-499f-a1b5-ac920c2dc644",
  MULTI_SELECT: "9a6b76cf-50c6-44a2-8e7c-e6556607ef78",
  TEXT_INPUT: "38e81e63-bb19-4ffe-a3b8-3da5b88aa6cb",
  SAME_DIFFERENT_MATRIX_TABLE: "56eabc94-dc22-4250-a782-d3da430dc822"
};

export const MOCK_ASSESSMENT_PAGE_ROUTE_INITIAL_ENTRIES = [routePaths.COGNITIVE_SKILLS_ASSESSMENT];
export const MOCK_LATERAL_PAGE_ROUTE_INITIAL_ENTRIES = [routePaths.LATERAL_ASSESSMENT];

export const MOCK_ASSESSMENT_CONTENT_PAGE_ROUTE_INITIAL_ENTRIES = [
  routePaths.COGNITIVE_SKILLS_ASSESSMENT_APP
];

export const AssessmentIds = {
  ECA_ASSESSMENT: "f0b8c493-3ad0-4ffe-81cc-dc9998c91aa3"
};
